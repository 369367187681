import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormGroup,
  FormHelperText,
  Box,
  Grid,
  Button,
} from "@mui/material";
import NumberFormat from "react-number-format";

import FormField from "./FormField";
import FormLabel from "./FormLabel";
import FormCheckbox from "./FormCheckbox";
import { submitLeadForm, useGlobalState } from "../context/context";

const phoneRegExp = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Email is not valid").required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Required"),
  products: Yup.array().min(1, "Must choose at least 1").required("Required"),
});

const options = [
  {
    value: "owner",
    headline: "Developing my existing lot",
    description: "Discover what I can build on my existing property.",
  },
  {
    value: "buyer",
    headline: "Buying a new home",
    description: "I’m looking for a property that is SB 9 eligible.",
  },
];

const LeadForm = () => {
  const { dispatch, state } = useGlobalState();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      products: ["owner"],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      submitLeadForm(dispatch, {
        ...values,
        recordId: state.queryResult.recordId,
        cityTier: state.queryResult.cityTier,
      });

      // Scroll to the top of the page after form submission
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Optional: for smooth scrolling effect
      });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs>
            <Box
              noValidate
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr 1fr" },
                gap: 1,
              }}
            >
              <FormField
                label="First name"
                name="firstName"
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <FormField
                label="Last name"
                name="lastName"
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
              <FormField
                label="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <NumberFormat
                format="(###) ###-####"
                mask="_"
                customInput={FormField}
                label="Phone"
                name="phone"
                type="tel"
                onChange={formik.handleChange}
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              sx={{
                textTransform: "none",
                fontWeight: 700,
                borderRadius: "15px",
              }}
              size="large"
            >
              Get Your Yardsworth Now
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LeadForm;

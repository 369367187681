import { useEffect, useState } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

import { useGlobalState } from "../context/context";
import { HelpCircle } from "react-feather";
import Tooltip from "./Tooltip";

const ellipsis = keyframes`
    to {
      width: 1em;    
    }
`;

const erorrMessage = (error) => {
  switch (error.errorType) {
    case "STREET_QUERY":
      return "Looks like you searched for a street. Please search for an individual parcel.";
    case "NO_ADDRESS":
      return "We seem to be missing this parcel in our database. Please try another address.";
    case "CANNOT_REACH_SERVER":
      return "We're having some technical difficulties. Please try refreshing.";
    case "RATE_LIMIT":
      return "You've hit your maximum searches today!";
    default: {
      throw new Error(`Unhandled app state: ${error.errorType}`);
    }
  }
};

const parcelCopy = (state) => {
  switch (state.queryResult.eligibility.overall) {
    case "YES":
    case "MAYBE":
      return (
        <>
          Your property is <span style={{ color: "#2576CA" }}>eligible</span> under SB 9!
        </>
      );
    /* case "MAYBE":
      return (
        <>
          Your property{" "}
          <Tooltip
            title="Your property report (see below) shows that you meet most qualifications for SB 9 eligibility. 
The only thing missing is your property’s local zoning data. Please reach out for more info."
          >
            <span
              style={{
                color: "#2576CA",
                textDecoration: "underline",
                cursor: "pointer",
                position: "relative",
                whiteSpace: "nowrap",
              }}
            >
              <HelpCircle
                size={16}
                style={{ position: "absolute", right: "-5px", top: "-10px" }}
              />
              may be{" "}
            </span>
          </Tooltip>
          eligible for <span style={{ whiteSpace: "nowrap" }}>SB 9</span>.
        </>
      ); */
    case "NO":
      return (
        <>
          Your property is <span style={{ color: "#D2472A" }}>not eligible. </span>
          
        </>
      );
    default: {
      throw new Error(
        `Unhandled eligibility: ${state.queryResult.eligibility.overall}`
      );
    }
  }
};


const coordinateCopy = (state) => {
  if (state.queryResult.eligibility.mostLikely) {
    return (
      <>
        Your property is <span style={{ color: "#D2472A" }}>likely not eligible. </span>
        
      </>
    );
  } else {
    switch (state.queryResult.eligibility.overall) {
      case "YES":
      case "MAYBE":
        return (
          <>
            Your property is <span style={{ color: "#2576CA" }}>eligible</span> under SB 9!
          </>
        );
      /* case "MAYBE":
        return (
          <>
            Your property{" "}
            <Tooltip
              title="Your property report (see below) shows that you meet most qualifications for SB 9 eligability. 
There are further details we require to fully-determine your home's eligibility. Please reach out for more info."
            >
              <span
                style={{
                  color: "#2576CA",
                  textDecoration: "underline",
                  cursor: "pointer",
                  position: "relative",
                  whiteSpace: "nowrap",
                }}
              >
                <HelpCircle
                  size={16}
                  style={{ position: "absolute", right: "-5px", top: "-10px" }}
                />
                may be{" "}
              </span>
            </Tooltip>
            eligible for <span style={{ whiteSpace: "nowrap" }}>SB 9</span>.
          </>
        ); */
      case "NO":
        return (
          <>
            Your property{" "}
            <span style={{ color: "#D2472A" }}>
              is not eligible
            </span>
            {state.queryResult.eligibility.residential === "NO"
              ? " but you can search another lot!"
              : "."}
          </>
        );
      default: {
        throw new Error(
          `Unhandled eligibility: ${state.queryResult.eligibility.overall}`
        );
      }
    }
  }
};

const headlineCopy = (state) => {
  switch (state.appState) {
    case "QUERY":
      return (
        <>
          See if your property qualifies.
        </>
      );
    case "FORM":
      return state.queryResult.type === "Parcel"
        ? parcelCopy(state)
        : coordinateCopy(state);
    case "FORM_SUBMIT":
      return <>Thank you! Please check your inbox for an email from info@yardsworth.com. <br/><br/>It will include your Yardsworth™ backyard value estimate and next steps!</>;
    case "ERROR":
      return erorrMessage(state.error);
    default: {
      throw new Error(`Unhandled app state: ${state.appState}`);
    }
  }
};

const Headline = () => {
  const { state } = useGlobalState();
  const [delay, setDelay] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (state.loading && state.appState === "FORM") {
      window.setTimeout(() => setDelay(true), 3000);
    }
  });

  useEffect(() => {
    if (state.appState === "FORM" && state.loading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [state.appState, state.loading]);

  if (state.loading) {
    return (
      <>
        <Typography
          variant="h4"
          sx={{
            "&::after": {
              overflow: "hidden",
              display: "inline-block",
              verticalAlign: "bottom",
              animation: `${ellipsis} steps(4,end) 900ms infinite`,
              content: '"\\2026"',
              width: "0px",
            },
          }}
        >
          {state.appState === "FORM_SUBMIT"
            ? "Submitting"
            : !delay
            ? "Searching for your property"
            : "Gathering property data"}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginTop: "10px" }}
        />
      </>
    );
  } else {
    return (
      <Typography variant="h4" sx={{ lineHeight: "130%" }}>
        {headlineCopy(state)}
      </Typography>
    );
  }
};

export default Headline;
